.flex-row
  display: flex
  flex-direction: row

.flex-column
  display: flex
  flex-direction: column

.align-center
  align-items: center

.justify-center
  justify-content: center

.justify-end
  justify-content: flex-end

.justify-start
  justify-content: flex-start

.justify-between
  justify-content: space-between

.grow
  flex-grow: 1

.no-grow
  flex-grow: 0

.shrink
  flex-shrink: 1

.no-shrink
  flex-shrink: 0

.wrap
  flex-wrap: wrap

.nowrap
  flex-wrap: nowrap
