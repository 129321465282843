@import "styles/colors.sass"
@import "styles/variables.sass"

.text-center
  text-align: center

.text-right
  text-align: right

.truncate
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis

.capitalized
  text-transform: capitalize

.uppercase
  text-transform: uppercase

.weight-700
  font-weight: 700

.weight-800
  font-weight: 800

.no-text-decoration
  text-decoration: none

.text-color
  color: $text-color

.secondary-text-color
  color: $secondary-text-color

.main-line-height
  line-height: 1.1rem !important

.keep-words
  word-break: keep-all

.break-word
  word-break: break-word

.text-color-white
  color: white

.text-sub
  color: $teal

.text-mobile-width-breakpoint
  display: none

  @media only screen and (max-width: $xs-ends)
    display: block

.text-tablet-width-breakpoint
  display: none

  @media only screen and (min-width: $xs-ends+1)
    display: block

.text-desktop-width-breakpoint
  display: none

  @media only screen and (min-width: $sm-ends+1)
    display: block
