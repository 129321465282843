// Breakpoints (taken from material ui)
$xs-ends: 767px // updated to a higher value as per requirements.
$sm-ends: 959px
// $md-starts: 1280px
// $lg-starts: 1920px

$app-border-radius: 1rem
$app-button-radius: 0.5rem

$title-xs-size: 2.25rem // 36px
$title-xs-line-height: 2.6875rem // 43px

$subtitle-xs-size: 1.5rem // 24px
$subtitle-xs-line-height: 1.8125rem // 29px
$subtitle-xs-letter-spacing: -0.01875rem // -0.3px

$text-xs-size: 1.25rem // 20px
$text-xs-line-height: 1.5rem // 24px

$font-family: "Nunito Sans", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif
