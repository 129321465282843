$blue-gray: #32424D

$blue-gray-light: #546875
$blue-gray-transparent: rgba(#546875, .5)
$gray-chateau: #98A0A6
$gallery: #ECECEC

$gray-dark: #1b2329
$gray-black: #505050
$gray-mid: #878787
$gray-light: #aaaaaa
$gray-lighter: #D9D9D9
$gray-lightest: #F6F6F6
$white: #FFFFFF

$alert-red: #EF767A
$teal: #2DBBBE
$teal2: #2BD2BC
$blue: #00AEEF
$green: #58D6A4
$black: #000000
$text-color: #546875
$title-color: #32424D
$secondary-text-color: rgba(0, 0, 0, 0.54)
$placeholder-color: $gray-chateau
$support-color: #F5F3F6

$gradient: linear-gradient(121deg, #20CDCD 0%, #2ECCB6 50%, #43D298 100%)
$gradientDark: linear-gradient(121deg, darken(#20CDCD, 12%) 0%, darken(#2ECCB6, 12%) 50%, darken(#43D298, 12%) 100%)
$cardGradient: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(0,0,0,0) 68%, rgba(0,0,0,0) 70%, rgba(0,0,0,0.73) 100%)

$headerBoxShadow: 0 0.125rem 0.25rem -0.0625rem rgba(0, 0, 0, 0.2), 0 0.25rem 1rem 0 rgba(0, 0, 0, 0), 0 0.0625rem 0 0 rgba(0, 0, 0, 0)
