@import url('https://use.typekit.net/pjw8bzp.css')
@import "colors.sass"
@import "variables.sass"
@import "flex.sass"

*
  box-sizing: border-box

html
  font-size: 16px

body
  width: 100vw
  margin: 0
  padding: 0
  overflow-x: hidden
  overflow-y: auto
  background-color: #fff !important
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  font-family: $font-family

code
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace

p,
a,
b,
pre,
code,
input
  color: $text-color

a
  text-decoration: none

.remove-outline
  &, &:hover, &:focus
    outline: none

.pointer
  cursor: pointer

.reset-list-styles
  list-style-type: none
  margin: 0
  padding: 0

.full-height
  height: 100%

.line
  height: 0.25rem
  width: 100%
  background: $gray-lightest

.overflow-hidden
  overflow: hidden

.loading
  width: 100vw
  height: 100vh
  position: fixed
  top: 0
  left: 0
  z-index: 200
  background: $white
  display: flex
  align-items: center
  justify-content: center

@keyframes loaded
  0%
    opacity: 0.1

  100%
    opacity: 1
